<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />     
        </div>
        <div v-else>
            <table-car-state :carState="carState" @timerAfto="timerAfto"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import tableCarState from '@/views/component/Table/tableCarState.vue'
export default {
    components: {
        tableCarState,
        ToastificationContent,
    },
data() {
    return {
        showPreloader: false,
        carState: [],
        fields: [
            { key: 'id', label: 'ID',},
            { key: 'division_name', label: 'Подразделение'},
            { key: 'performer_login', label: 'Логин'},
            { key: 'district', label: 'Район'},
            { key: 'performer_balance', label: 'Баланс исполнителя'},
            { key: 'perfomer_id', label: 'ID исполнителя'},
            { key: 'full_name_performer', label: 'Ф.И.О'},
            { key: 'performer_transport_model', label: 'Модель исполнитель'},
            { key: 'performer_transport_number', label: 'Номер исполнитель'},
            { key: 'performer_transport_id', label: 'ID Заказа'},
            { key: 'performer_lng', label: 'Широта'},
            { key: 'performer_lat', label: 'Долгота'},
            { key: 'date_time', label: 'Дата'},
            { key: 'performer_is_active', label: 'Статус'}
        ],
    }
},
mounted() {
    this.$store.commit('pageData/setdataCount', null)
    this.openFilter()
},
methods: {
    openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            }); 
            if (arrayRoutes.includes(this.$route.path) == true){
                this.showPreloader = true
                this.$http
                .get(`${this.$route.name}`,{params: param}).then(res => {
                    console.log(this.$route.name);
                    this.carState = res.data
                    this.$store.commit('pageData/setdataCount', this.carState.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
            }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
        },
        sendToParent(tableData){
        this.carState = tableData
        this.$store.commit('pageData/setdataCount', this.carState.length)
	  },
      refresh() {
        this.showPreloader = true
        this.$http
        .get('car-state')
        .then(res => {
            this.carState = res.data
            this.$store.commit('pageData/setdataCount', this.carState.length)
            this.showPreloader = false
            this.$store.commit('REFRESH_DATA', false)
        }).catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка!',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: err.response.data.errors,
          },
            })
        })
    },
    refreshIs_Active() {
        this.$http
        .get('car-state')
        .then(res => {
            this.carState = res.data
        })
    },
    timerAfto(is_active) {
        is_active ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000)) : clearInterval(this.timer);
    },
},
computed: {
    fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData
    }
},
watch: {
    fetchingNewData(val) {
            if(val) {
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.carState = res.data
                    this.$store.commit('pageData/setdataCount', this.carState.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
}
}
</script>
